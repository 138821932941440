@keyframes keyRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sun {
  margin: 12px;
  fill: orange;
  animation: keyRotate 16s linear infinite;
  animation-name: keyRotate;
}